<template>
  <section class="navigation">
    <div class="upper mb-5">
      <div class="container is-fluid">
        <div class="logo" v-if="hasAdmin === true">
          <router-link to="client-management">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 420.96 129.08"><path d="M110.05-207.66" transform="translate(-27.95 276.46)" style="fill:none;stroke:#231f20;stroke-miterlimit:10;stroke-width:0.6503000259399414px"/><polygon points="100.7 110 84.2 72.8 87.4 100.3 33.9 63.4 0 78.5 100.7 110" style="fill:#00adee"/><polygon points="42.1 35.5 0.6 40.4 33.9 63.4 55.3 53.9 42.1 35.5" style="fill:#b91c8d"/><polygon points="83.2 64.1 93.9 17.8 73.5 13.1 16.6 0 42.1 35.5 79.3 31.1 83.2 64.1" style="fill:#e41e3a"/><polygon points="72.4 46.3 82.2 68.3 83.2 64.1 79.3 31.1 42.1 35.5 55.3 53.9 72.4 46.3" style="fill:#fdbb28"/><polygon points="55.3 53.9 78.2 85.7 82.2 68.3 72.4 46.3 55.3 53.9" style="fill:#fff"/><polygon points="84.2 72.8 82.2 68.3 78.2 85.7 55.3 53.9 33.9 63.4 87.4 100.3 84.2 72.8" style="fill:#062c61"/><text transform="translate(116.41 110)" style="isolation:isolate;font-size:53.76076889038086px;font-family:ProximaNova-SemiboldIt, Proxima Nova;font-weight:600;font-style:italic">Huddle Area</text></svg>
          </router-link>
        </div>
        <div class="logo" v-if="hasUser === true">
          <router-link to="event-management">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 420.96 129.08"><path d="M110.05-207.66" transform="translate(-27.95 276.46)" style="fill:none;stroke:#231f20;stroke-miterlimit:10;stroke-width:0.6503000259399414px"/><polygon points="100.7 110 84.2 72.8 87.4 100.3 33.9 63.4 0 78.5 100.7 110" style="fill:#00adee"/><polygon points="42.1 35.5 0.6 40.4 33.9 63.4 55.3 53.9 42.1 35.5" style="fill:#b91c8d"/><polygon points="83.2 64.1 93.9 17.8 73.5 13.1 16.6 0 42.1 35.5 79.3 31.1 83.2 64.1" style="fill:#e41e3a"/><polygon points="72.4 46.3 82.2 68.3 83.2 64.1 79.3 31.1 42.1 35.5 55.3 53.9 72.4 46.3" style="fill:#fdbb28"/><polygon points="55.3 53.9 78.2 85.7 82.2 68.3 72.4 46.3 55.3 53.9" style="fill:#fff"/><polygon points="84.2 72.8 82.2 68.3 78.2 85.7 55.3 53.9 33.9 63.4 87.4 100.3 84.2 72.8" style="fill:#062c61"/><text transform="translate(116.41 110)" style="isolation:isolate;font-size:53.76076889038086px;font-family:ProximaNova-SemiboldIt, Proxima Nova;font-weight:600;font-style:italic">Huddle Area</text></svg>
          </router-link>
        </div>
        <div class="logo" v-if="hasUser === false && hasAdmin === false">
          <p class="title">Huddle</p>
        </div>
        <div class="is-flex is-justify-content-center is-align-items-center">
          <a class="button is-info is-outlined" v-on:click="logout()">
            <strong>Logout</strong>
          </a>
        </div>
      </div>
    </div>
  </section>
  <router-view></router-view>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

import router from '../../router';

export default {
  name: 'admin',
  data() {
    return {
      user: {},
      userID: '',
      adminAccount: {},
      navElements: [],
      hasAdmin: false,
      hasUser: false,
    };
  },
  created() {
    const checkUser = JSON.parse(sessionStorage.getItem('user'));
    const checkAdmin = JSON.parse(sessionStorage.getItem('admin'));
    // console.log(checkUser, checkAdmin);
    if (checkUser === null && checkAdmin !== null) {
      this.user = JSON.parse(sessionStorage.getItem('admin'));
      this.hasAdmin = true;
    }
    if (checkUser !== null && checkAdmin === null) {
      this.user = JSON.parse(sessionStorage.getItem('user'));
      this.hasAdmin = false;
      this.hasUser = true;
    }
    if (checkUser !== null && checkAdmin !== null) {
      this.user = JSON.parse(sessionStorage.getItem('user'));
      this.hasAdmin = true;
    }
  },
  methods: {
    logout() {
      try {
        sessionStorage.clear();
        firebase.auth().signOut()
          .then((data) => {
            router.push('/login');
          })
          .catch((error) => {
            console.error('Problem logging out: ', error);
          });
      } catch (e) {
        console.error('Problem logging out: ', e);
      }
    },
    removeUser() {
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('admin');
    },
  },
};
</script>

<style lang="scss" scoped>
  .navigation {
    width: 100%;
    height: auto;

    padding: 0;
    margin: 0;

    background: #f5f6fa;

    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;

    .container {
      width: 100%;
    }

    .upper {
      width: 100%;
      height: auto;

      padding: 10px 0 10px 0;

      border-bottom: 3px solid #7853F6;

      .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: space-between;
      }

      .logo {
        width: 100%;
        max-width: 200px;

        @media screen and (max-width: 48rem) {
          max-width: 130px;
        }
      }

      .title {
        font: 600 1.3rem "Helvetica Neue", sans-serif;
      }
    }
  }
</style>
